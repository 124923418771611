import React from "react";
import GetinTouch from "../GetinTouch";
import Finance from "../MainMenu/Finance";
import AboutUs from "../MainMenu/AboutUs";
import Banner from "../Banner";
import ReadMore from "../MainMenu/ReadMore";
import career from "../../Assets/career.png";

function Career(props) {
  return (
    <>
      <div className="container px-0">
        <ReadMore
          name={props?.data?.career?.heading || "Careers"}
          section="career"
        />
      </div>
      {/* <AboutUs
        para1={
          "IREP Dhan is not just a company; it's a community of innovators, creators, and visionaries. We're passionate about leveraging technology to drive businesses forward, and we're looking for like-minded individuals to join us on this exciting journey."
        }
        para2={
          "We're also committed to nurturing young talent. If you're a student looking for internships in the IT field, drop us an email with your CV at below mentioned email."
        }
      /> */}
      <GetinTouch
        image={props?.data?.["career"].image}
        description={props?.data?.["career"].description}
        email={props?.data?.["career"].email}
        address={props?.data?.["career"].address}
      />
      <Finance whyChoose={props?.data?.career?.whyChooseIrep} />

      <Banner img={props?.data?.career?.banner} />
    </>
  );
}

export default Career;
