import React from "react";
import Notfound from "../../Assets/not-found.png";

function NotFound() {
  return (
    <div className="container">
      <img src={Notfound} className="mx-auto" style={{ display: "block" }} />
    </div>
  );
}

export default NotFound;
