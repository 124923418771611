import React from "react";
import HomeGraph from "../../Assets/hero-section.webp";

const ReadMore = (props) => {
  return (
    <section
      className={`container-fluid pt-5`}
      style={{ borderRadius: "30px" }}
    >
      <div className="align-items-center justify-content-center justify-content-lg-start">
        <div
          style={{ position: "relative", width: "100%", borderRadius: "20px" }}
        >
          <div
            className={`${
              props?.section ? props?.section : "demo"
            }-wrap col-lg-12`}
            data-aos="fade-up"
            data-aos-duration="1400"
          >
            {/* <img class="demo-bg" src={HomeGraph} alt="" /> */}
            <div className="demo-content">
              <p
                className="para text-center center"
                style={{ textAlign: "center" }}
              >
                {props?.name ? props.name : `Welcome To IREP Dhan`}
              </p>
              {/* <p className="para2 text-center " style={{ textAlign: "center" }}>
              An associate of the esteemed IREP Credit Capital.
            </p>
            <p className="para2 text-center">
              We are an IT service provider company with a distinct focus. Our
              dedication lies in supporting the growth and success of Micro,
              Small, and Medium Enterprises (MSMEs) through specialized IT
              services. As an associate of IREP Credit Capital, we bring a
              wealth of expertise and resources to the table, ensuring that our
              clients receive top-notch IT solutions tailored to their unique
              needs.
            </p> */}
            </div>
          </div>
        </div>
        {/* <div
          className="col-lg-12"
          data-aos="fade-up"
          data-aos-duration="800"
          // style={{
          //   backgroundImage: `url(${HomeGraph})`,
          //   backgroundRepeat: "no-repeat",
          //   backgroundSize: "100% 500%",
          // }}
        >
          <p className="para text-center text-md-start">
            Welcome to IREP Dhan, an associate of the esteemed IREP Credit
            Capital.
          </p>
          <p className="para2 text-center text-lg-start">
            We are an IT service provider company with a distinct focus. Our
            dedication lies in supporting the growth and success of Micro,
            Small, and Medium Enterprises (MSMEs) through specialized IT
            services. As an associate of IREP Credit Capital, we bring a wealth
            of expertise and resources to the table, ensuring that our clients
            receive top-notch IT solutions tailored to their unique needs.
          </p>
          <div className="d-flex justify-content-center justify-content-lg-start">
            <button className="px-3 py-2 readmore_btn">Read More</button>
          </div>
        </div> */}
        {/* <div
          data-aos="fade-up"
          data-aos-duration="800"
          className=" col-md-9 col-lg-6"
        >
          <img
            className="w-100 mt-5 mt-lg-0 rounded opacity-75"
            src={HomeGraph}
          />
        </div> */}
      </div>
    </section>
  );
};

export default ReadMore;
