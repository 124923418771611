import React from "react";
import phoneicon from "../../Assets/phoneicon.png";
import person1 from "../../Assets/Mr. Prince Yelati.webp";
import person2 from "../../Assets/Mr. Ronak Doshi.jpeg";
import person3 from "../../Assets/Mr. Manmohan Shetty.jpeg";

const OurAchivment = (props) => {
  const backgroundGradient = {
    width: "100%",
    backgroundImage: "linear-gradient(  #fff 70%,white 30%)",
    // #0074cc
  };
  const card = {
    boxShadow: "5px 4px 6px 6px rgba(0, 0, 0, 0.1)",
    backgroundColor: "#fff",
    maxWidth: "380px",
    marginLeft: "auto",
    marginRight: "auto",
    boxSizing: "border-box",
    cursor: "pointer",
    borderRadius: "20px",
    overflow: "hidden",
  };
  return (
    <div style={backgroundGradient}>
      <div className="container-fluid">
        <div className="pt-5 pb-5">
          {props?.about && (
            <p
              style={{ color: "#000", fontSize: "2.3rem", textAlign: "center" }}
            >
              {props?.title || "Managerial Board"}
            </p>
          )}
          <p
            className="pb-3 max_w_para"
            style={{
              color: "grey",
              fontSize: "20px",
              // textAlign: "center",
              marginLeft: "auto",
              marginRight: "auto",
              textAlign: "justify",
            }}
          >
            {props?.description
              ? props.description
              : `Our Managerial board consists of experienced and dedicated industry
            professionals who bring a wealth of expertise and knowledge to guide
            our company’s endeavours. Their leadership shapes our strategies and
            propels us towards achieving our vision.`}
          </p>
          {/* <div className="row justify-content-center">
            <div className=" col-sm-6 col-md-4 col-lg-3">
              <p
                className="mb-0"
                style={{
                  color: "#fff",
                  fontSize: "50px",
                  textAlign: "center",
                  fontWeight: "400",
                }}
              >
                600
              </p>
              <p
                className="mb-0 pb-3"
                style={{ color: "#fff", fontSize: "20px", textAlign: "center" }}
              >
                AWARDS WON
              </p>
            </div>
            <div className=" col-sm-6 col-md-4 col-lg-3">
              <p
                className="mb-0"
                style={{
                  color: "#fff",
                  fontSize: "50px",
                  textAlign: "center",
                  fontWeight: "400",
                }}
              >
                585
              </p>
              <p
                className="mb-0 pb-3"
                style={{ color: "#fff", fontSize: "20px", textAlign: "center" }}
              >
                SATISFIED CLIENTS
              </p>
            </div>
            <div className=" col-sm-6 col-md-4 col-lg-3">
              <p
                className="mb-0"
                style={{
                  color: "#fff",
                  fontSize: "50px",
                  textAlign: "center",
                  fontWeight: "400",
                }}
              >
                560
              </p>
              <p
                className="mb-0 pb-3"
                style={{ color: "#fff", fontSize: "20px", textAlign: "center" }}
              >
                STARTUP CROWTH
              </p>
            </div>
            <div className=" col-sm-6 col-md-4 col-lg-3">
              <p
                className="mb-0"
                style={{
                  color: "#fff",
                  fontSize: "50px",
                  textAlign: "center",
                  fontWeight: "400",
                }}
              >
                280
              </p>
              <p
                className="mb-0 pb-3"
                style={{ color: "#fff", fontSize: "20px", textAlign: "center" }}
              >
                AVERAGE PRICES
              </p>
            </div>
          </div> */}
        </div>
        <div className="row justify-content-center">
          {props?.persons?.map((ele, index) => {
            return (
              <div className=" col-md-6 col-lg-4 text-center pt-2">
                <div
                  style={card}
                  data-aos="fade-right"
                  data-aos-duration={"2000"}
                >
                  <a
                    href={ele.route}
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    <img src={ele.img} style={{ width: "100%" }} />
                    <h5 className="pt-2">{ele.name}</h5>
                    <h6>{ele.position}</h6>
                  </a>
                </div>
              </div>
            );
          })}
          {/* <div className=" col-md-6 col-lg-4 text-center pt-2">
            <div style={card} data-aos="fade-right" data-aos-duration="1000">
              <a
                href="/prince-yelati"
                style={{ textDecoration: "none", color: "black" }}
              >
                <img src={person1} style={{ width: "100%" }} />
                <h5 className="pt-2">Mr. Prince Yelati</h5>
                <h6>Director</h6>
                {props?.person1?.description && (
                  <p
                    className="pt-2 pb-4"
                    style={{
                      maxWidth: "280px",
                      marginLeft: "auto",
                      marginRight: "auto",
                      color: "grey",
                    }}
                  >
                    Our Managerial board consists of experienced and dedicated
                    industry professionals who bring a wealth of expertise and
                    knowledge to guide our company’s endeavours. Their
                    leadership shapes our strategies and propels us towards
                    achieving our vision.
                  </p>
                )}
              </a>
            </div>
          </div>
          <div className=" col-md-6 col-lg-4 text-center pt-2">
            <div style={card} data-aos="fade-right" data-aos-duration="2000">
              <a
                href="/ronak-doshi"
                style={{ textDecoration: "none", color: "black" }}
              >
                <img src={person2} style={{ width: "100%" }} />
                <h5 className="pt-2"> Mr. Ronak Doshi</h5>
                <h6>Director</h6>
                {props?.person2?.description && (
                  <p
                    className="pt-2 pb-4"
                    style={{
                      maxWidth: "280px",
                      marginLeft: "auto",
                      marginRight: "auto",
                      color: "grey",
                    }}
                  >
                    Regular Business Law Assistance service includes monitoring
                    the legal sphere of your business.
                  </p>
                )}
              </a>
            </div>
          </div>
          <div className="col-md-6 col-lg-4 text-center pt-2">
            <div style={card} data-aos="fade-right" data-aos-duration="3000">
              <a
                href="/manmohan-prabhakar"
                style={{ textDecoration: "none", color: "black" }}
              >
                <img src={person3} style={{ width: "100%" }} />
                <h5 className="pt-2 text-wrap">
                  Mr. Manmohan Prabhakar Shetty
                </h5>
                <h6>Director</h6>
                {props?.person3?.description && (
                  <p
                    className="pt-2 pb-4"
                    style={{
                      maxWidth: "280px",
                      marginLeft: "auto",
                      marginRight: "auto",
                      color: "grey",
                    }}
                  >
                    Regular Business Law Assistance service includes monitoring
                    the legal sphere of your business.
                  </p>
                )}
              </a>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default OurAchivment;
