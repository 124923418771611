import React from "react";
import finance from "../Assets/get-in-touch.png";
const GetinTouch = (props) => {
  return (
    <div className="container">
      {/* <p
        className="text-center mt-5 "
        style={{ fontSize: "40px", color: "black", fontWeight: "400" }}
      >
        Get In Touch
      </p> */}

      <div className="row py-2 align-items-center justify-content-center justify-content-lg-start">
        <div className="col-lg-1"></div>
        <div className="col-lg-6 ">
          <p
            // className="text-start"
            style={{
              fontSize: "1rem",
              color: "black",
              fontWeight: "400",
              textAlign: "justify",
            }}
          >
            {props?.description
              ? props.description
              : `We are a team of like-minded people who do business, generate ideas and
        realize them. Finance, Marketing, Jurisprudence and Business Economy are
        our directions and we know how to deal with many financial issues.`}
          </p>
          <p
            style={{ fontSize: "1rem", color: "grey", fontWeight: "400" }}
            className="mb-0 mt-4 "
          >
            <span style={{ color: "black" }}>Email:</span>
            <a
              href={`mailto:${props?.email ? props.email : ""}`}
              style={{ textDecoration: "none", cursor: "pointer" }}
              className="ms-3"
            >
              {props?.email ? props.email : ""}
            </a>
          </p>
          {/* <p
            style={{ fontSize: "16px", color: "grey", fontWeight: "400" }}
            className="p-4 border mb-0 mt-4"
          >
            Contact No.<span className="ms-3">9876543210 </span>
          </p> */}
          <p
            style={{
              fontSize: "1rem",
              color: "grey",
              fontWeight: "400",
              display: "flex",
              flexDirection: "row",
            }}
            className=" mt-4 mb-0 pl-0  "
          >
            <div style={{ color: "black" }}>Address:</div>
            <div className="ms-3 ">{props?.address ? props.address : ""}</div>
          </p>
          {/* <div className="d-flex justify-content-center justify-content-lg-start mt-4">
            <button className="px-3 py-2 readmore_btn">Contact Us</button>
          </div> */}
        </div>
        <div className=" col-sm-10 col-md-8  col-lg-4">
          <img
            style={{ height: "50%", width: "100%" }}
            src={props?.image ? props.image : finance}
          />
        </div>
      </div>
    </div>
  );
};

export default GetinTouch;
