import React from "react";
import ResultGraph from "../../Assets/about-us.png";

const AboutUs = (props) => {
  return (
    <div className="container">
      <div className="row align-items-center pt-5 lg:py-5 justify-content-center justify-content-lg-start">
        <div
          className=" col-sm-9 col-md-10 col-lg-6 d-none d-lg-block"
          // data-aos="fade-right"
          // data-aos-duration="1000"
        >
          <img
            className="w-100 mt-5 mt-lg-0"
            style={{ borderRadius: "20px" }}
            src={props?.img ? props.img : ResultGraph}
          />
        </div>
        <div className="col-lg-6" data-aos="fade-left" data-aos-duration="1000">
          <p
            className="text-center text-lg-start text-sm-start"
            style={{
              fontSize: "2.3rem",
              color: "black",
              fontWeight: "400",
              fontFamily: "Poppins",
            }}
          >
            {props?.title ? props.title : "About Us"}
          </p>
          <div
            className=" col-sm-9 col-md-10 col-lg-6 d-block d-lg-none pb-3"
            // data-aos="fade-right"
            // data-aos-duration="1000"
          >
            <img
              className="w-100 mt-lg-0"
              style={{ borderRadius: "20px" }}
              src={ResultGraph}
            />
          </div>
          <p
            className=""
            // className="text-justify"
            style={{
              fontSize: "1rem",
              color: "grey",
              fontWeight: "400",
              fontFamily: "Poppins",
              textAlign: "justify",
            }}
          >
            {props?.para1
              ? props.para1
              : `We are associate of the esteemed IREP Credit Capital. We are an IT
            service provider company with a distinct focus. Our dedication lies
            in supporting the growth and success of Micro, Small, and Medium
            Enterprises (MSMEs) through specialized IT services. As an associate
            of IREP Credit Capital, we bring a wealth of expertise and resources
            to the table, ensuring that our clients receive top-notch IT
            solutions tailored to their unique needs`}
          </p>
          {props?.para2 && (
            <p
              // className="text-lg-start text-md-start"
              style={{
                fontSize: "1rem",
                color: "grey",
                fontWeight: "400",
                fontFamily: "Poppins",
                textAlign: "justify",
              }}
            >
              {props?.para2
                ? props.para2
                : `We are associate of the esteemed IREP Credit Capital. We are an IT
            service provider company with a distinct focus. Our dedication lies
            in supporting the growth and success of Micro, Small, and Medium
            Enterprises (MSMEs) through specialized IT services. As an associate
            of IREP Credit Capital, we bring a wealth of expertise and resources
            to the table, ensuring that our clients receive top-notch IT
            solutions tailored to their unique needs`}
            </p>
          )}
          <div className="d-flex justify-content-center align-items-center">
            {/* <button
              style={{
                backgroundColor: "#0074cc",
                color: "#fff",
                outline: "none",
                border: "none",
                borderRadius: "3px",
              }}
              className="px-3 py-2"
            >
              Read More
            </button> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
