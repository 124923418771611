import React from "react";
import GetStarted from "../MainMenu/GetStarted";
import GetinTouch from "../GetinTouch";
import Banner from "../Banner";
import ReadMore from "../MainMenu/ReadMore";

function Support(props) {
  return (
    <>
      <div className="container px-0">
        <ReadMore
          name={props?.name ? props.name : "Support"}
          section={props?.section ? props.section : ""}
        />
      </div>
      <div className="py-4">
        <GetinTouch
          image={props?.image ? props.image : ""}
          email={props?.email ? props.email : ""}
          address={props?.address ? props.address : ""}
          description={
            props?.description
              ? props.description
              : "We understand that technology is the backbone of modern business, and any technical hiccup can disrupt operations. That's why our dedicated customer support team is here to provide you with timely assistance, reliable solutions, and a seamless experience."
          }
        />
      </div>
      <Banner img={props?.banner} />
    </>
  );
}

export default Support;
