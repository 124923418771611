import React from "react";
import facebook from "../Assets/facebook.webp";
import navlogo from "../Assets/navlogo.jpg";

const Footer = (props) => {
  console.log(props, "props");
  return (
    <div className="bg_blue">
      <div className="container-fluid border_footer pb-3">
        <div className="container">
          <div className="d-lg-flex justify-content-between pt-5 pb-1">
            <div className="col-lg-4 col-md-12">
              <a
                href="/"
                className="d-inline page_logo cursor-pointer pb-sm-4"
                style={{
                  maxWidth: "300px",
                  width: "100%",
                  textDecoration: "none",
                }}
              >
                <span className="nav_logo pb-sm-4">
                  <img className="w_logo pb-sm-4" src={navlogo} alt="navlogo" />
                </span>
                <p
                  style={{
                    textDecoration: "none",
                    textAlign: "justify",
                    color: "white",
                    fontSize: "1rem",
                  }}
                >
                  {props?.data?.footer?.discription
                    ? props?.data?.footer?.discription
                    : `We are an IT service provider company with a distinct focus.
                  Our dedication lies in supporting the growth and success of
                  Micro, Small, and Medium Enterprises (MSMEs) through
                  specialized IT services.`}
                </p>
                {/* <span>
                We are an IT service provider company with a distinct focus. Our
                dedication lies in supporting the growth and success of Micro,
                Small, and Medium Enterprises (MSMEs) through specialized IT
                services.
              </span> */}
              </a>
            </div>
            {/* <div className="d-flex justify-content-center justify-content-md-start gap-3 mt-3 mt-md-0">
            <p
              style={{ cursor: "pointer" }}
              className="text-white cursor-pointer"
            >
              HOME
            </p>
            <p
              style={{ cursor: "pointer" }}
              className="text-white cursor-pointer"
            >
              CAREER
            </p>
            <p
              style={{ cursor: "pointer" }}
              className="text-white cursor-pointer"
            >
              CONTACTUS
            </p>
            <p
              style={{ cursor: "pointer" }}
              className="text-white cursor-pointer"
            >
              ABOUTUS
            </p>
          </div> */}
            <div
              className="col-lg-4 col-md-12 lg:justify-content-end"
              style={{
                // maxWidth: "300px",
                // width: "100%",
                display: "flex",
                justifyContent: window.screen.width < 700 ? "" : "end",
              }}
            >
              <ul className="ps-0 mb-0 pt-2 justify-content-md-start  align-items-center gap-4">
                <li className="pb-3">
                  <a className="text-white underline">
                    {props?.data?.footer?.company?.title || "COMPANY"}
                  </a>
                </li>
                {props?.data?.footer?.company?.menu.map((e) => {
                  return (
                    <li>
                      <a
                        href={e.route}
                        className=" text-decoration-none text-gray-400"
                        style={{
                          fontSize: "1.1rem",
                          color: "#fff",
                          lineHeight: "25px",
                        }}
                      >
                        {e.title}
                      </a>
                    </li>
                  );
                })}
                {/* <li>
                  <a
                    href="/services"
                    className="text-decoration-none"
                    style={{
                      fontSize: "1.1rem",
                      color: "#fff",
                      lineHeight: "25px",
                    }}
                  >
                    Services
                  </a>
                </li>

                <li>
                  <a
                    href="/board"
                    className="text-decoration-none"
                    style={{
                      fontSize: "1.1rem",
                      color: "#fff",
                      lineHeight: "25px",
                    }}
                  >
                    Board
                  </a>
                </li>
                <li>
                  <a
                    href="/our-presence"
                    className="text-decoration-none"
                    style={{
                      fontSize: "1.1rem",
                      color: "#fff",
                      lineHeight: "25px",
                    }}
                  >
                    Presence
                  </a>
                </li> */}
              </ul>
            </div>
            <div
              className="col-lg-4 col-md-12 lg:justify-content-end"
              style={{
                // maxWidth: "300px",
                // width: "100%",
                display: "flex",
                justifyContent: window.screen.width < 700 ? "" : "end",
              }}
            >
              <ul className="ps-0 mb-0 pt-2  align-items-center  gap-4">
                <li className="pb-3">
                  <a className="text-white underline">
                    {props?.data?.footer?.contact?.title || "CONTACT"}
                  </a>
                </li>
                {props?.data?.footer?.contact?.menu?.map((e) => {
                  return (
                    <li>
                      <a
                        href={e.route}
                        className="text-decoration-none "
                        style={{
                          fontSize: "1.1rem",
                          color: "#fff",
                          lineHeight: "25px",
                        }}
                      >
                        {e.title}
                      </a>
                    </li>
                  );
                })}
                {/* <li>
                  <a
                    href="/contact-us"
                    className=" text-decoration-none 
                  "
                    style={{
                      fontSize: "1.1rem",
                      color: "#fff",
                      lineHeight: "25px",
                    }}
                  >
                    Contact Us
                  </a>
                </li>
                <li>
                  <a
                    href="/customer-support"
                    className=" text-decoration-none 
                  "
                    style={{
                      fontSize: "1.1rem",
                      color: "#fff",
                      lineHeight: "25px",
                    }}
                  >
                    Customer Support
                  </a>
                </li> */}
              </ul>
            </div>

            <div>
              {/* <div className="d-flex justify-content-center justify-content-md-start gap-3 mt-3 mt-md-0">
              <img
                className="facebook_icon cursor-pointer"
                src={facebook}
                alt="facebook"
              />
              <img
                className="facebook_icon cursor-pointer"
                src={facebook}
                alt="facebook"
              />
              <img
                className="facebook_icon cursor-pointer"
                src={facebook}
                alt="facebook"
              />
            </div> */}
              {/* <div className="d-flex align-items-center mt-4 gap-4 justify-content-center">
              <button className="px-3 py-2 buy_btn">Buy</button>
              <button className="px-3 py-2 Learn_btn">Learn More</button>
            </div> */}
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-between pt-4">
        <p className="text-white text-center w-100">
          {props?.data?.footer?.bottom ||
            "IREP Dhan Private Limited &#169; 2023 . All Rights Reserved"}
        </p>
        {/* <div className="d-flex gap-3 justify-content-center justify-content-sm-start">
            <p
              className="mb-0"
              style={{ fontSize: "14px", color: "white", fontWeight: "600" }}
            >
              PRIVACY POLICY
            </p>
            <p
              className="mb-0"
              style={{ fontSize: "14px", color: "white", fontWeight: "600" }}
            >
              TERM OF SERVICE
            </p>
          </div> */}
      </div>
    </div>
  );
};

export default Footer;
