import React from "react";
import HomeGraph from "../Assets/HomePageGraph.png";
import ReadMore from "../Components/MainMenu/ReadMore";
import OurAchivment from "../Components/MainMenu/OurAchivment";
import Result from "../Components/MainMenu/Result";
import Finance from "../Components/MainMenu/Finance";
import GetStarted from "../Components/MainMenu/GetStarted";
import Header from "../Common/Header";
import Networking from "../Components/Networking";
import GetinTouch from "../Components/GetinTouch";
import Footer from "../Common/Footer";
import AboutUs from "../Components/MainMenu/AboutUs";
import Banner from "../Components/Banner";
import { Helmet } from "react-helmet";

const HomeScreen = (props) => {
  return (
    <div>
      {/* <Header /> */}
      <Helmet>
        <meta
          name="description"
          content="Welcome To IREP Dhan: specializes in IT services tailored to empower Micro, Small, and Medium Enterprises (MSMEs) for growth and success. Explore our solutions today."
        ></meta>
      </Helmet>
      <section
        // className="container-fluid pt-2"
        style={{ margin: "auto", paddingRight: 0, paddingLeft: 0 }}
      >
        <ReadMore
          name={
            props.data?.home?.title
              ? props.data.home?.title
              : "Welcome To IREP Dhan"
          }
        ></ReadMore>
      </section>

      {/* <OurAchivment></OurAchivment> */}
      <div className="container" style={{ margin: "auto" }}>
        <AboutUs
          img={props.data?.home?.aboutUsImg}
          para1={props.data?.home?.aboutUs}
          title={props.data?.home?.aboutUsHeading}
        ></AboutUs>
      </div>
      <div className="container" style={{ margin: "auto" }}>
        <Result
          image={props.data?.home?.servicesImg}
          title={props.data?.home?.servicesHeading}
          para1={props.data?.home?.services}
          // heading={props.data?.home?.aboutUsHeading}
        ></Result>
      </div>
      <div className="container" style={{ margin: "auto" }}>
        <Finance
          whyChoose={props?.data?.home?.whyChooseIrep}
          title={props.data?.home?.whyChooseIrepDhanHeading}
        ></Finance>
      </div>
      <Banner img={props.data?.home?.banner} />
    </div>
  );
};

export default HomeScreen;
