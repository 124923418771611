import React, { useEffect, useState } from "react";
import HomeScreen from "../src/Screens/HomeScreen";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../src/App.css";
import "./index.css";
import AOS from "aos";
import "aos/dist/aos.css";
import { BrowserRouter, Routes, Route, HashRouter } from "react-router-dom";
import AboutUs from "./Components/MainMenu/AboutUs";
import Aboutus from "./Components/AboutUs";
import Team from "./Components/Team";
import Services from "./Components/Services";
import Support from "./Components/Support";
import Career from "./Components/Career";
import OurPresence from "./Components/OurPresence";
import Header from "./Common/Header";
import Footer from "./Common/Footer";
import NotFound from "./Components/NotFound";
import contactUs from "./Assets/contact-us.png";
import Result from "./Components/MainMenu/Result";
import Prince from "./Assets/Mr. Prince Yelati.webp";
import Raunak from "./Assets/Mr. Ronak Doshi.jpeg";
import Manmohan from "./Assets/Mr. Manmohan Shetty.jpeg";
import Banner from "./Components/Banner";
import axios from "axios";

const App = () => {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  const [data, setdata] = useState();

  const fetchData = async () => {
    const res = await axios.get("/content.json");
    // console.log(res);
    setdata(res.data);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <BrowserRouter>
      <Header data={data} />
      <Routes>
        <Route path="/" element={<HomeScreen data={data} />} />
        <Route path="/about-us" element={<Aboutus data={data} />} />
        <Route path="/services" element={<Services data={data} />} />
        <Route
          path="/contact-us"
          element={
            <Support
              name={"Contact Us"}
              section="contactus"
              description={data?.["contact-us"].description}
              image={data?.["contact-us"].image}
              email={data?.["contact-us"].email}
              address={data?.["contact-us"].address}
              banner={data?.["contact-us"].banner}
            />
          }
        />
        <Route
          path="/customer-support"
          element={
            <Support
              name={"Customer Support"}
              section="contactus"
              image={data?.["contact-us"].image}
              description={data?.["customer-support"].description}
              email={data?.["customer-support"].email}
              address={data?.["customer-support"].address}
              banner={data?.["customer-support"].banner}
            />
          }
        />
        <Route path="/career" element={<Career data={data} />} />
        <Route
          path="/our-presence"
          element={
            <OurPresence
              image={data?.["our-presence"].image}
              heading={data?.["our-presence"].heading}
              para2heading={data?.["our-presence"].para2heading}
              para3heading={data?.["our-presence"].para3heading}
              para1={data?.["our-presence"].para1}
              para2={data?.["our-presence"].para2}
              para3={data?.["our-presence"].para3}
              para4={data?.["our-presence"].para4}
              banner={data?.["our-presence"].banner}
            />
          }
        />
        <Route path="/board" element={<Team data={data} />} />
        <Route
          path="/prince-yelati"
          element={
            <>
              <Result
                title={data?.["about-us"].persons[0].name}
                image={data?.["about-us"].persons[0].img}
                linkedIn={data?.["about-us"].persons[0].profile}
                position="Director, IREP Dhan"
                para3={data?.["about-us"].persons[0].detailPara3}
                para2={data?.["about-us"].persons[0].detailPara2}
                para1={data?.["about-us"].persons[0].detailPara1}
              />
              <Banner img={data?.["about-us"].banner} />
            </>
          }
        />
        <Route
          path="/ronak-doshi"
          element={
            <>
              <Result
                title={data?.["about-us"].persons[1].name}
                image={data?.["about-us"].persons[1].img}
                linkedIn={data?.["about-us"].persons[1].profile}
                position="Director, IREP Dhan"
                para3={data?.["about-us"].persons[1].detailPara3}
                para2={data?.["about-us"].persons[1].detailPara2}
                para1={data?.["about-us"].persons[1].detailPara1}
              />
              <Banner img={data?.["about-us"].banner} />
            </>
          }
        />
        <Route
          path="/manmohan-prabhakar"
          element={
            <>
              <Result
                title={data?.["about-us"].persons[2].name}
                image={data?.["about-us"].persons[2].img}
                linkedIn={data?.["about-us"].persons[2].profile}
                position="Director, IREP Dhan"
                para3={data?.["about-us"].persons[2].detailPara3}
                para2={data?.["about-us"].persons[2].detailPara2}
                para1={data?.["about-us"].persons[2].detailPara1}
              />
              <Banner img={data?.["about-us"].banner} />
            </>
          }
        />
        <Route path="*" element={<NotFound />} />
      </Routes>
      {data && <Footer data={data} />}
    </BrowserRouter>
  );
};

export default App;
