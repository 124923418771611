import React from "react";
import ResultGraph from "../../Assets/our-services.png";

const Result = (props) => {
  return (
    <div className="container pt-5">
      <div className="row align-items-center py-lg-5 justify-content-center justify-content-lg-start">
        <div
          className="col-lg-6"
          data-aos="fade-right"
          data-aos-duration="1000"
        >
          <p
            className="text-center text-lg-start "
            style={{
              fontSize: "2.3rem",
              color: "black",
              fontWeight: "400",
              fontFamily: "Poppins",
            }}
          >
            {props?.title ? props.title : `Our Services`}
          </p>
          {props?.position && (
            <div className="d-flex">
              <p
                className="text-center text-lg-start"
                style={{
                  fontSize: "1.3rem",
                  color: "black",
                  fontWeight: "400",
                  fontFamily: "Poppins",
                }}
              >
                {props?.position ? props?.position : `Our Services`}
              </p>

              {props?.linkedIn && (
                <p className="ml-5">
                  <img
                    onClick={() => {
                      window.open(props?.linkedIn, "_blank_");
                    }}
                    className="facebook_icon cursor-pointer"
                    width="58"
                    height="58"
                    style={{ width: "28px", height: "28px" }}
                    src={"https://img.icons8.com/fluency/58/linkedin.png"}
                    alt="facebook"
                  />
                </p>
              )}
            </div>
          )}
          <div
            className=" col-sm-9 col-md-10 col-lg-6 d-lg-none pb-3"
            // data-aos="fade-left"
            // data-aos-duration="1000"
          >
            <img
              className="w-100  mt-lg-0"
              src={props?.image ? props.image : ResultGraph}
            />
          </div>

          {
            <p
              // className="text-lg-start"
              style={{
                fontSize: "1rem",
                color: "grey",
                fontWeight: "400",
                fontFamily: "Poppins",
                textAlign: "justify",
              }}
            >
              {props?.para1
                ? props.para1
                : `At IREP Dhan, we are dedicated to revolutionizing the way businesses
            leverage technology. As a dynamic and forward-thinking IT service
            providing company, we pride ourselves on delivering cutting-edge
            solutions that empower your business to thrive in the digital age.`}
            </p>
          }
          {props?.para2 && (
            <p
              // className="text-lg-start"
              style={{
                fontSize: "1rem",
                color: "grey",
                fontWeight: "400",
                fontFamily: "Poppins",
                textAlign: "justify",
              }}
            >
              {props?.para2
                ? props.para2
                : `At IREP Dhan, we are dedicated to revolutionizing the way businesses
            leverage technology. As a dynamic and forward-thinking IT service
            providing company, we pride ourselves on delivering cutting-edge
            solutions that empower your business to thrive in the digital age.`}
            </p>
          )}
          {props?.para3 && (
            <p
              // className="text-lg-start"
              style={{
                fontSize: "1rem",
                color: "grey",
                fontWeight: "400",
                fontFamily: "Poppins",
                textAlign: "justify",
              }}
            >
              {props?.para3
                ? props.para3
                : `At IREP Dhan, we are dedicated to revolutionizing the way businesses
            leverage technology. As a dynamic and forward-thinking IT service
            providing company, we pride ourselves on delivering cutting-edge
            solutions that empower your business to thrive in the digital age.`}
            </p>
          )}
          <div className="d-flex justify-content-center align-items-center">
            {/* <button
              style={{
                backgroundColor: "#0074cc",
                color: "#fff",
                outline: "none",
                border: "none",
                borderRadius: "3px",
              }}
              className="px-3 py-2"
            >
              Read More
            </button> */}
          </div>
        </div>
        <div
          className=" col-sm-9 col-md-10 col-lg-6 d-none d-lg-block"
          // data-aos="fade-left"
          // data-aos-duration="1000"
        >
          <img
            className="w-100 mt-5 mt-lg-0"
            src={props?.image ? props.image : ResultGraph}
          />
        </div>
      </div>
    </div>
  );
};

export default Result;
