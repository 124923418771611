import React from "react";
import OurAchivment from "../MainMenu/OurAchivment";
import Banner from "../Banner";
import ReadMore from "../MainMenu/ReadMore";

function Team(props) {
  return (
    <>
      <div className="container px-0">
        <ReadMore name="Managerial Board" section="board" />
      </div>
      <div className="pt-5 pb-5">
        <OurAchivment
          description={
            "Meet the visionary minds behind IREP Dhan's success. Our managerial board consists of accomplished leaders with diverse expertise, united by a shared passion for driving innovation, growth, and client satisfaction."
          }
          persons={props?.data?.["about-us"].persons}
        />
      </div>
      <Banner img={props?.data?.["about-us"].banner} />
    </>
  );
}

export default Team;
