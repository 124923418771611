import React from "react";
import banner from "../../Assets/banner.jpg";

function Banner(props) {
  return (
    <div className="container py-5 my-5 col-lg-5 col-sm-12 col-md-9">
      <img src={props?.img ? props.img : banner} className="mx-auto w-100" />
    </div>
  );
}

export default Banner;
