import React from "react";
import finance from "../../Assets/why-choose.png";

const Finance = (props) => {
  return (
    <div>
      <div className="container">
        <div className="lg:pt-5 row align-items-center justify-content-center justify-content-lg-start">
          <div
            className=" col-sm-10 col-md-8  col-lg-6 d-none d-lg-block"
            data-aos="zoom-in"
            data-aos-duration="1000"
          >
            <img className="w-100" src={finance} />
          </div>
          <div
            className="col-lg-6"
            data-aos="zoom-out"
            data-aos-duration="1000"
          >
            <div className="text-center text-lg-start">
              <p
                style={{
                  fontSize: "2.3rem",
                  color: "black",
                  fontWeight: "500",
                  fontFamily: "Poppins",
                }}
                className="mb-0 pt-3"
              >
                {props?.title ? props.title : "Why Choose IREP Dhan?"}
              </p>
            </div>
            <div
              className=" col-sm-10 col-md-8  col-lg-6 d-block d-lg-none pb-3"
              data-aos="zoom-in"
              data-aos-duration="1000"
            >
              <img className="w-100" src={finance} />
            </div>
            {props?.whyChoose?.map((ele) => {
              return (
                <div className="text-start">
                  <p
                    style={{
                      fontSize: "1.3rem",
                      color: "black",
                      fontWeight: "400",
                    }}
                    className="mb-0 pt-3"
                  >
                    {ele.title}
                  </p>
                  <p
                    style={{
                      fontSize: "1rem",
                      color: "grey",
                      fontWeight: "400",
                      maxWidth: "580px",
                      textAlign: "justify",
                    }}
                  >
                    {ele.description}
                  </p>
                </div>
              );
            })}
            {/* <div className="text-start">
              <p
                style={{
                  fontSize: "1.3rem",
                  color: "black",
                  fontWeight: "400",
                }}
                className="mb-0 pt-3"
              >
                01. Expertise
              </p>
              <p
                style={{
                  fontSize: "1rem",
                  color: "grey",
                  fontWeight: "400",
                  maxWidth: "580px",
                  textAlign: "justify",
                }}
              >
                Backed by the robust foundation of IREP Credit Capital, we bring
                a wealth of knowledge and a proven track record of success in
                the IT realm.
              </p>
            </div>
            <div className="text-start">
              <p
                style={{
                  fontSize: "1.3rem",
                  color: "black",
                  fontWeight: "400",
                  maxWidth: "580px",
                  textAlign: "justify",
                }}
                className="mb-0 pt-2"
              >
                02. Tailored Solutions
              </p>
              <p
                style={{
                  fontSize: "1rem",
                  color: "grey",
                  fontWeight: "400",
                  maxWidth: "580px",
                  textAlign: "justify",
                }}
              >
                We understand that one size does not fit all. Our solutions are
                tailor-made to suit your unique business needs and objectives.
              </p>
            </div>
            <div className="text-start">
              <p
                style={{
                  fontSize: "1.3rem",
                  color: "black",
                  fontWeight: "400",
                }}
                className="mb-0 pt-2"
              >
                03. Collaboration
              </p>
              <p
                style={{
                  fontSize: "1rem",
                  color: "grey",
                  fontWeight: "400",
                  maxWidth: "580px",
                  textAlign: "justify",
                }}
              >
                We believe in true partnership. We work closely with you,
                understanding your challenges and goals, to deliver solutions
                that make a tangible impact.
              </p>
            </div>
            <div className="text-start">
              <p
                style={{
                  fontSize: "1.3rem",
                  color: "black",
                  fontWeight: "400",
                }}
                className="mb-0 pt-2"
              >
                04. Innovation
              </p>
              <p
                style={{
                  fontSize: "1rem",
                  color: "grey",
                  fontWeight: "400",
                  maxWidth: "580px",
                  textAlign: "justify",
                }}
              >
                Technology is ever-evolving, and so are we. We stay at the
                forefront of technological advancements, ensuring that your
                business remains competitive.
              </p>
            </div>
            <div className="text-start">
              <p
                style={{
                  fontSize: "1.3rem",
                  color: "black",
                  fontWeight: "400",
                }}
                className="mb-0 pt-2"
              >
                05. Commitment to Excellence
              </p>
              <p
                style={{
                  fontSize: "1rem",
                  color: "grey",
                  fontWeight: "400",
                  maxWidth: "580px",
                  textAlign: "justify",
                }}
              >
                Our commitment to excellence is unwavering. We strive to exceed
                your expectations, delivering solutions that meet the highest
                standards.
              </p>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Finance;
