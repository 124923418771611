import React from "react";
import ResultGraph from "../../Assets/out-presence.png";
import Banner from "../Banner";
import ReadMore from "../MainMenu/ReadMore";

const OurPresence = (props) => {
  return (
    <>
      <div className="container px-0">
        <ReadMore name={props?.heading || "Our Presence"} section="presence" />
      </div>
      <div className="container py-5">
        <div className="row align-items-center  justify-content-center justify-content-lg-start">
          <div
            className=" col-sm-9 col-md-10 col-lg-6 d-none d-lg-block"
            // data-aos="fade-right"
            // data-aos-duration="1000"
          >
            <img
              className="w-100 mt-5 mt-lg-0"
              src={props?.image ? props.image : ResultGraph}
              style={{ borderRadius: "20px" }}
            />
            {/* <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3806.347580202681!2d78.47324197571476!3d17.443070283453658!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb9969985d0c25%3A0x56db5a49fdf6d0d2!2sIrep%20Dhan%20Private%20Limited!5e0!3m2!1sen!2sin!4v1694760087111!5m2!1sen!2sin"
              width="600"
              height="450"
              style={{ border: "0" }}
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe> */}
          </div>
          <div
            className="col-lg-6"
            data-aos="fade-left"
            data-aos-duration="1000"
          >
            {/* <p
              className="text-center text-lg-start text-sm-start"
              style={{
                fontSize: "2.3rem",
                color: "black",
                fontWeight: "400",
                fontFamily: "Poppins",
              }}
            >
              Our Presence
            </p> */}
            <div
              className=" col-sm-9 col-md-10 col-lg-6 d-block d-lg-none"
              // data-aos="fade-right"
              // data-aos-duration="1000"
            >
              <img
                className="w-100 mt-lg-0"
                src={ResultGraph}
                style={{ borderRadius: "20px" }}
              />
              {/* <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3806.347580202681!2d78.47324197571476!3d17.443070283453658!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb9969985d0c25%3A0x56db5a49fdf6d0d2!2sIrep%20Dhan%20Private%20Limited!5e0!3m2!1sen!2sin!4v1694760087111!5m2!1sen!2sin"
              width="600"
              height="450"
              style={{ border: "0" }}
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe> */}
            </div>
            <p
              // className="text-lg-start text-md-start"
              style={{
                fontSize: "1rem",
                color: "grey",
                fontWeight: "400",
                fontFamily: "Poppins",
                textAlign: "justify",
              }}
            >
              {props?.para1
                ? props.para1
                : `We are associate of the esteemed IREP Credit Capital. We are an IT
            service provider company with a distinct focus. Our dedication lies
            in supporting the growth and success of Micro, Small, and Medium
            Enterprises (MSMEs) through specialized IT services. As an associate
            of IREP Credit Capital, we bring a wealth of expertise and resources
            to the table, ensuring that our clients receive top-notch IT
            solutions tailored to their unique needs`}
            </p>
            <p
              style={{
                fontSize: "1rem",
                color: "grey",
                fontWeight: "400",
                fontFamily: "Poppins",
                textAlign: "justify",
                textDecoration: "underline",
              }}
            >
              {props?.para2heading || "Hyderabad: Where Ideas Take Shape"}
            </p>
            {props?.para2 && (
              <p
                // className="text-lg-start text-md-start"
                style={{
                  fontSize: "1rem",
                  color: "grey",
                  fontWeight: "400",
                  fontFamily: "Poppins",
                  textAlign: "justify",
                }}
              >
                {props?.para2
                  ? props.para2
                  : `We are associate of the esteemed IREP Credit Capital. We are an IT
            service provider company with a distinct focus. Our dedication lies
            in supporting the growth and success of Micro, Small, and Medium
            Enterprises (MSMEs) through specialized IT services. As an associate
            of IREP Credit Capital, we bring a wealth of expertise and resources
            to the table, ensuring that our clients receive top-notch IT
            solutions tailored to their unique needs`}
              </p>
            )}
            <p
              style={{
                fontSize: "1rem",
                color: "grey",
                fontWeight: "400",
                fontFamily: "Poppins",
                textAlign: "justify",
                textDecoration: "underline",
              }}
            >
              {props?.para3heading || "Mumbai: Our Visionary Headquarters"}
            </p>
            {props?.para3 && (
              <p
                // className="text-lg-start text-md-start"
                style={{
                  fontSize: "1rem",
                  color: "grey",
                  fontWeight: "400",
                  fontFamily: "Poppins",
                  textAlign: "justify",
                }}
              >
                {props?.para3
                  ? props.para3
                  : `We are associate of the esteemed IREP Credit Capital. We are an IT
            service provider company with a distinct focus. Our dedication lies
            in supporting the growth and success of Micro, Small, and Medium
            Enterprises (MSMEs) through specialized IT services. As an associate
            of IREP Credit Capital, we bring a wealth of expertise and resources
            to the table, ensuring that our clients receive top-notch IT
            solutions tailored to their unique needs`}
              </p>
            )}
            {props?.para4 && (
              <p
                // className="text-lg-start text-md-start"
                style={{
                  fontSize: "1rem",
                  color: "grey",
                  fontWeight: "400",
                  fontFamily: "Poppins",
                  textAlign: "justify",
                }}
              >
                {props?.para4
                  ? props.para4
                  : `We are associate of the esteemed IREP Credit Capital. We are an IT
            service provider company with a distinct focus. Our dedication lies
            in supporting the growth and success of Micro, Small, and Medium
            Enterprises (MSMEs) through specialized IT services. As an associate
            of IREP Credit Capital, we bring a wealth of expertise and resources
            to the table, ensuring that our clients receive top-notch IT
            solutions tailored to their unique needs`}
              </p>
            )}
            <div className="d-flex justify-content-center align-items-center">
              {/* <button
              style={{
                backgroundColor: "#0074cc",
                color: "#fff",
                outline: "none",
                border: "none",
                borderRadius: "3px",
              }}
              className="px-3 py-2"
            >
              Read More
            </button> */}
            </div>
          </div>
        </div>
      </div>
      <Banner img={props?.banner} />
    </>
  );
};

export default OurPresence;
