import React from "react";
import AboutUs from "../MainMenu/AboutUs";
import Result from "../MainMenu/Result";
import OurAchivment from "../MainMenu/OurAchivment";
import Banner from "../Banner";
import ReadMore from "../MainMenu/ReadMore";

function Aboutus(props) {
  return (
    <>
      <div className="container px-0">
        <ReadMore
          name={props?.data?.["about-us"]?.heading || "About Us"}
          section="about"
        />
      </div>
      <AboutUs
        img={
          props.data?.home?.aboutUsImg
            ? props.data?.home?.aboutUsImg
            : "/about-us.png"
        }
        title={props?.data?.["about-us"]?.heading}
        para1={props?.data?.["about-us"]?.para1}
        para2={props?.data?.["about-us"]?.para2}
      />
      <Result
        image={props.data?.["about-us"]?.servicesImg}
        title={props.data?.["about-us"]?.servicesHeading}
        para1={props.data?.["about-us"]?.services}
      />
      <OurAchivment
        title={props.data?.["about-us"]?.boardMembertitle}
        description={props.data?.["about-us"]?.boardMemberDescription}
        persons={props?.data?.["about-us"].persons}
        about={true}
      />
      <Banner img={props?.data?.["about-us"]?.banner} />
    </>
  );
}

export default Aboutus;
