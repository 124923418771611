import React, { useState } from "react";
import navlogo from "../Assets/navlogo.jpg";

const Header = (props) => {
  const [openNav, setopenNav] = useState(true);
  if (openNav) {
    document.body.classList.remove("overflow-hidden");
  } else {
    document.body.classList.add("overflow-hidden");
  }
  return (
    <>
      <nav className="pt-3">
        <div className="container container_modified d-flex align-items-center justify-content-between">
          <div className="d-flex w-100 align-items-center  justify-content-between">
            <a href="/" className="d-inline page_logo">
              <span className="nav_logo ">
                <img className="w_logo" src={navlogo} alt="navlogo" />
              </span>
            </a>
            <div
              onClick={() => setopenNav(false)}
              className="d-flex justify-content-evenly c_pointer gap-1 d-inline-block flex-column  d-lg-none opacity-100 align-items-end"
            >
              <span className="menu_line"></span>
              <span className="menu_line_2 "></span>
              <span className="menu_line"></span>
            </div>

            <ul className="ps-0 mb-0 d-none d-lg-flex  align-items-center gap-4">
              {props.data?.header.map((ele) => {
                return (
                  <li>
                    <a
                      href={ele.route}
                      className="text-decoration-none text-black"
                    >
                      {ele.title}
                    </a>
                  </li>
                );
              })}
              {/* <li>
                <a
                  href="/about-us"
                  className=" text-decoration-none text-black
                  "
                >
                  ABOUT US
                </a>
              </li>
              <li>
                <a href="/board" className="text-decoration-none text-black">
                  BOARD
                </a>
              </li>
              <li>
                <a href="/services" className="text-decoration-none text-black">
                  SERVICES
                </a>
              </li>
              <li>
                <a
                  href="/our-presence"
                  className="text-decoration-none text-black"
                >
                  PRESENCE
                </a>
              </li>

              <li>
                <a
                  href="/contact-us"
                  className=" text-decoration-none text-black
                  "
                >
                  CONTACT US
                </a>
              </li> */}
            </ul>
          </div>
        </div>

        <div
          className={
            openNav ? "showNav d-lg-none d-block" : "hideNav d-lg-none d-block"
          }
        >
          <ul className="ps-0 mb-0 position-relative d-flex gap-4 justify-content-center w-100 min-vh-100 flex-column align-items-center">
            <button
              onClick={() => setopenNav(true)}
              className="btn btn-close  bg-transparent opacity-100  position-absolute top-0 end-0  me-4"
            ></button>
            {props.data?.header.map((ele) => {
              return (
                <li onClick={() => setopenNav(true)}>
                  <a
                    href={ele.route}
                    className="text-decoration-none text-black"
                  >
                    {ele.title}
                  </a>
                </li>
              );
            })}

            {/* <li onClick={() => setopenNav(true)}>
              <a
                href="/about-us"
                className=" text-decoration-none text-black
                  "
              >
                ABOUT US
              </a>
            </li>
            <li onClick={() => setopenNav(true)}>
              <a href="/board" className="text-decoration-none text-black">
                BOARD
              </a>
            </li>
            <li onClick={() => setopenNav(true)}>
              <a
                href="/services"
                className=" text-decoration-none text-black
                  "
              >
                SERVICES
              </a>
            </li>
            <li onClick={() => setopenNav(true)}>
              <a
                href="/our-presence"
                className=" text-decoration-none text-black
                  "
              >
                PRESENCE
              </a>
            </li>
            <li onClick={() => setopenNav(true)}>
              <a
                href="/contact-us"
                className=" text-decoration-none text-black
                  "
              >
                CONTACT US
              </a>
            </li> */}
          </ul>
        </div>
      </nav>
    </>
  );
};

export default Header;
