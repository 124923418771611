import React from "react";
import Result from "../MainMenu/Result";
import Banner from "../Banner";
import ReadMore from "../MainMenu/ReadMore";

function Services(props) {
  return (
    <>
      <div className="container px-0">
        <ReadMore name={props?.data?.["services"].heading} section="service" />
      </div>

      <Result
        image={props?.data?.["services"]?.servicesImg}
        title={props?.data?.["services"]?.servicesHeading}
        para1={props?.data?.["services"].para1}
        para2={props?.data?.["services"].para2}
        para3={props?.data?.["services"].para3}
      />
      <Banner img={props?.data?.["services"].banner} />
    </>
  );
}

export default Services;
